import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/discordCard.css';

const DiscordCard = () => {
    const { t } = useTranslation();

  return (
    <div className="card-container">
        <div className="card nitro-card">
            <div className="card-header">
                <div
                    style={{ background: "url('./img/banner.gif')" }}
                    className="banner-img"
                ></div>
            </div>
            <div className="card-body">
                <div className="profile-header">
                    <div className="profil-logo">
                        <img src="./img/me.jpg" />
                        <div className="status online"></div>
                    </div>
                    <div className="badges-container">
                        <div className="badge-item">
                            <img src="./img/badges/bravery.webp" alt="" />
                            <div className="tooltip tooltip-up">20yo</div>
                        </div>
                        <div className="badge-item">
                            <img src="./img/badges/nitro.webp" alt="" />
                            <div className="tooltip tooltip-up">French</div>
                        </div>
                        <div className="badge-item">
                            <img src="./img/badges/boost.webp" alt="" />
                            <div className="tooltip tooltip-up">
                                Student
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-body">
                    <div className="username">
                    Rémi.L<span>#7669</span>
                    <div className="badge">✔ BOT</div>
                    </div>
                    <hr />
                    <a href="mailto:contact@remi-lvg.com" target="_blank" class="btn">
                        {t('DiscordCard.ContactMe')}
                    </a>
                    <div className="basic-infos">
                        <div className="category-title">{t('AboutMe.Title')}</div>
                        <p>
                            {t('DiscordCard.Description')}
                        </p>
                    </div>
                    <div className="basic-infos">
                    <div className="category-title">{t('DiscordCard.OnEarthSince')}</div>
                    <p>{t('DiscordCard.BirthDate')}</p>
                    </div>
                    <div className="roles">
                        <div className="category-title">Hobbies</div>
                        <div className="roles-list">
                            <div className="role">
                                <div className="role-color" style={{background: "red"}}></div>
                                <p>{t('DiscordCard.Hobbies.Gaming')}</p>
                            </div>
                            <div className="role">
                                <div className="role-color" style={{background: "violet"}}></div>
                                <p>{t('DiscordCard.Hobbies.Cooking')}</p>
                            </div>
                            <div className="role">
                                <div className="role-color" style={{background: "green"}}></div>
                                <p>{t('DiscordCard.Hobbies.Gym')}</p>
                            </div>
                            <div className="role">
                                <div className="role-color" style={{background: "yellow"}}></div>
                                <p>{t('DiscordCard.Hobbies.Badminton')}</p>
                            </div>
                            <div className="role">
                                <div className="role-color" style={{background: "yellowgreen"}}></div>
                                <p>{t('DiscordCard.Hobbies.Programming')}</p>
                            </div>
                            <div className="role role-add">
                                <div className="role-add-text">+</div>
                            </div>
                        </div>
                    </div>
                    <div className="note">
                        <div className="category-title">Note</div>
                        <textarea placeholder={t('DiscordCard.NotePlaceholder')}></textarea>
                    </div>
                    <div className="message">
                        <input type="text" placeholder={t('DiscordCard.MessagePlaceholder')}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default DiscordCard;
