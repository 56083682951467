import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/skills.css';

const skillsData = {
  frontend: [
    { name: 'HTML', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg' },
    { name: 'CSS', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg' },
    { name: 'JavaScript', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg' },
    { name: 'React', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg' },
  ],
  backend: [
    { name: 'C', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg' },
    { name: 'C++', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg' },
    { name: 'C#', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg' },
    { name: 'Python', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg' },
    { name: 'Bash', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg' },
    { name: 'Lua', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/lua/lua-original.svg' },
    { name: 'Node.js', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg' },
    { name: 'PHP', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg' },
    { name: 'Java', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg' },
  ],
  database: [
    { name: 'PostgreSQL', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg' },
    { name: 'MySQL', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg' },
    { name: 'SQLite', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sqlite/sqlite-original.svg' },
  ],
  tools: [
    { name: 'Docker', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg' },
    { name: 'Git', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg' },
    { name: 'Drone (CI)', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/drone/drone-original.svg' },
    { name: 'SonarQube', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sonarqube/sonarqube-original.svg' },
  ],
  ide: [
    { name: 'Visual Studio Code', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg' },
    { name: 'Visual Studio', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-original.svg' },
    { name: 'CLion', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/clion/clion-original.svg' },
    { name: 'Rider', icon: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rider/rider-original.svg' },
  ]
};

const Skills = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState('frontend');

  const renderSkills = (category) => {
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
        {skillsData[category].map((skill, index) => (
          <div key={index} className="skill-card bg-white dark:bg-gray-700 p-4 rounded-lg shadow-md flex flex-col items-center text-center">
            <img src={skill.icon} alt={skill.name} className="icon mb-2" />
            <h3 className="text-lg font-semibold">{skill.name}</h3>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section id="skills" className="skills-section py-16 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="section-title text-4xl font-bold mb-8">{t('Skills')}</h2>
        <div className="tabs-container mb-8">
          <ul className="tabs flex border-b-2 border-gray-300 dark:border-gray-600">
            <li className={`tab-item ${selectedCategory === 'frontend' ? 'active' : ''}`} onClick={() => setSelectedCategory('frontend')}>
              {t('Frontend')}
            </li>
            <li className={`tab-item ${selectedCategory === 'backend' ? 'active' : ''}`} onClick={() => setSelectedCategory('backend')}>
              {t('Backend')}
            </li>
            <li className={`tab-item ${selectedCategory === 'database' ? 'active' : ''}`} onClick={() => setSelectedCategory('database')}>
              {t('Bases de données')}
            </li>
            <li className={`tab-item ${selectedCategory === 'tools' ? 'active' : ''}`} onClick={() => setSelectedCategory('tools')}>
              {t('Outils')}
            </li>
            <li className={`tab-item ${selectedCategory === 'ide' ? 'active' : ''}`} onClick={() => setSelectedCategory('ide')}>
              {t('IDE/Editeurs')}
            </li>
          </ul>
        </div>
        <div className="tab-content">
          {renderSkills(selectedCategory)}
        </div>
      </div>
    </section>
  );
};

export default Skills;
